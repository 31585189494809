import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {}

const Analytics = (props: Props) => {
	return (
		<>
			<Helmet>
				<title>Analytics</title>
			</Helmet>
			<div className="page">
				<h1 className="page__title">Analytics</h1>
			</div>
		</>
	);
};

export default Analytics;
